@font-face {
  font-family: 'Carmen';
  src: local('Carmen'), url(../assets/fonts/Carmen\ Sans\ Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Carmen';
  font-weight: 700;
  src: local('Carmen'), url(../assets/fonts/Carmen\ Sans\ Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Carmen';
  font-weight: 400;
  src: local('Carmen'), url(../assets/fonts/Carmen\ Sans\ SemiBold.otf) format('opentype');
}

body.full header.top-bar, body.full section.left-menu {
  display: none;
}

body.full div.pages {
  left: 0 !important;
  top: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: #00a8cd !important;
}

body.full section.topBar {
  width: 1920px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body.full section.topBar img {
  width: 153px;
  margin: auto;
  margin-left: 40px;
}

body.full section.topBar .dateTime {
  font-family: 'Carmen';
  font-weight: bold;
  font-size: 40px;
  color: #fff;
  margin-right: 40px;
}

body.full section.container {
  display: flex;
  justify-content: space-between;
}

body.full section.container .room {
  display: flex;
  width: 940px;
  height: 920px;
  background-color: rgba(255, 255, 255, 0.9);
  /* opacity: 0.9; */
}

body.full section.container .room.left{
  border-radius: 0 32px 32px 0;
}

body.full section.container .room.right{
  border-radius: 32px 0 0 32px;
}

.nonCalBar {
  max-width: 300px;
  /* margin: auto; */
  margin-left: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nonCalBar.reverse {
  margin-left: 0;
  margin-right: 48px;
  text-align: right;
}

.nonCalBar .title {
  font-size: 64px;
  font-family: 'Carmen';
  margin: 0;
  margin-top: 68px;
  color: #011e57d9;
}

.nonCalBar .qrContainer {
  flex: 1;
  padding: 30px;
  background-color: #fff;
  margin-top: 22px;
  margin-bottom: 30px;
  border-radius: 16px;
  box-shadow: 0 0 21px -4px #00000025;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.nonCalBar .qrContainer p {
  font-size: 20px;
  text-align: center;
  color: #011e57d9;
  line-height: 31px;
  font-weight: 600;
  width: 180px;
  margin: auto;
  font-family: 'Carmen';
}

.nonCalBar .qrContainer img {
  margin: auto;
}

.nonCalBar .bottomPic {
  width: 86%;
  margin: auto;
}

.nonCalBar .bottomPic.reverse {
  transform: scaleX(-1);
}

.calBar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 58px;
  padding-top: 165px;
}

.calBar .calendar {
  display: flex;
  flex-wrap: wrap;
}

.calBar .calendar div {
  width: 93px;
  height: 75px;
  font-size: 24px;
  font-family: 'Carmen';
  color: #011e57d9;
  /* border: solid 1px; */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 13px;
}

.calBar .calendar div p {
  margin: auto;
}

.calBar .calendar div.disabled  {
  background: #05a8cd;
  color: #fff;
  padding: 0px;
}
.calBar .calendar div.expired  {
  opacity: .3;
}


.calBar .calendar div.current {
  background-color: #b7e2ed;
  margin: 8px;
  padding: 5px;
}

.calBar .bottomLabel {
  font-size: 16px;
  font-family: 'Carmen';
  color: #011e57d9;
  position: relative;
  padding-left: 70px;
}

.calBar .bottomLabel:before {
  content: " ";
  background-color: #011e57d9;
  width: 40px;
  height: 16px;
  position: absolute;
  top: 3px;
  left: 24px;
  border-radius: 15px;
}



.calBar .bottomLabel-expired {
  font-size: 16px;
  font-family: 'Carmen';
  color: #cbccd0d9;
  position: relative;
  padding-left: 70px;
}

.calBar .bottomLabel-expired:before {
  content: " ";
  background-color: #cbccd0d9;
  width: 40px;
  height: 16px;
  position: absolute;
  top: 3px;
  left: 24px;
  border-radius: 15px;
}


.calBar .bottomLabel-selected {
  font-size: 16px;
  font-family: 'Carmen';
  color: #05a8cd;
  position: relative;
  padding-left: 70px;
}

.calBar .bottomLabel-selected:before {
  content: " ";
  background-color: #05a8cd;
  width: 40px;
  height: 16px;
  position: absolute;
  top: 3px;
  left: 24px;
  border-radius: 15px;
}